require('angular');
require('angular-animate');
require('angular-aria');
require('angular-material');
require('angular-material/angular-material.min.css');
require('angular-messages');
require('material-design-icons');
require('angular-route');
require('angular-animate');
require('./js/app.js');
require('./css/app.css');
import 'material-icons/iconfont/material-icons.css';
