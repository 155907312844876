const HTTP = new WeakMap();


export default {
    name : 'taskList',
    config : {
        bindings         : {
            filter:"<"
        },
        templateUrl: '/static/src/js/components/taskList/taskList.html',
        controller: ['$http', class taskList {
            constructor($http) {
                HTTP.set(this, $http);
            }

            $onInit() {
                HTTP.get(this).get("/api/v1/task/").then(
                    result => this.tasks = result.data.data,
                    error => console(error)
                );
            };

            deleteTask(id){
                let ctrl = this;
                if (confirm("Delete this task?")) {
                    HTTP.get(this).delete("/api/v1/task/" + id + "/").then(
                        result => {
                            let remove_id = ctrl.tasks.findIndex(function (item) {
                                return item.id == id;
                            });
                            ctrl.tasks.splice(remove_id, 1);
                        },
                        error => console.log(error)
                    )
                }
            }

            updateZone(enable, task, zone){
                let ctrl = this;
                task.errors = [];
                HTTP.get(this)({
                    method:"PATCH",
                    url:"/api/v1/task/"+ task.id + "/",
                    data:{
                        zone: zone.name,
                        enable: zone.enable,
                    }
                }).then(
                    result => {
                        task = result.data;
                    },
                    error => {
                        task.errors = error.data.errors;
                        zone.enable = !zone.enable;
                    }
                )
            }

        }]
    }
};