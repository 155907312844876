const HTTP = new WeakMap();


export default {
    name : 'logsList',
    config : {
        bindings         : {
            filter:"<"
        },
        templateUrl: '/static/src/js/components/logsList/logsList.html',
        controller: ['$http', class logsList {
            constructor($http) {
                HTTP.set(this, $http);
            }

            $onInit() {
                HTTP.get(this).get("/api/v1/logs/").then(
                    result => this.logs = result.data.data,
                    error => console(error)
                );
            };

        }]
    }
};