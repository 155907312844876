import taskList from "./components/taskList/taskList"
import logsList from "./components/logsList/logsList"
export default angular.module( 'app', [ 'ngMaterial', 'ngRoute', 'ngAnimate'] )
    .component(taskList.name, taskList.config)
    .component(logsList.name, logsList.config)

    .directive('goto', ['$location', function($location) {
        return {
            link: function(scope, element, attrs) {
                element.on('click', function() {
                    scope.$apply(function() {
                        $location.path(attrs.goto);
                    });
                });
            }
        }
    }])

    .config(['$locationProvider' ,'$routeProvider',
        function config($locationProvider, $routeProvider) {
            $locationProvider.hashPrefix('!');

            $routeProvider.
            when('/tasks', {
                template: '<task-list filter="filter"></task-list>'
            }).
            when('/logs', {
                template: '<logs-list filter="filter"></logs-list>'
            }).
            otherwise('/tasks');
        }])

    .config(["$mdThemingProvider", $mdThemingProvider => {
        $mdThemingProvider.theme('docs-dark', 'default')
            .primaryPalette('grey')
            .dark();
    }]);
